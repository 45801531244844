import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import DefaultLayout from '../../layout/default';
import Page from '../../components/common/page';

import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import {
	EuiForm,
	EuiPanel,
	EuiSpacer,
	EuiFormRow,
	EuiButton,
	EuiFieldText,
	EuiFieldPassword,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiTextColor,
} from '@elastic/eui';

const Auth = () => {
	const auth = useContext(AuthContext);
	const [isLoginForm, setIsLoginForm] = useState(true);
	const [errorMessage, setErrorMessage] = useState();
	const { isLoading, httpError, sendRequest, clearHttpError } = useHttpClient();

	const { register, errors, reset, clearErrors, handleSubmit } = useForm();

	const handleAuthFormChange = () => {
		reset();
		setIsLoginForm(!isLoginForm);
		clearHttpError();
		clearErrors();
	};

	useEffect(() => {
		if (httpError) {
			switch (httpError.code) {
				case 401:
				case 404:
					setErrorMessage('Credenziali non valide');
					break;
				default:
					setErrorMessage('Si è verificato un errore, si prega di riprovare');
					break;
			}
		} else {
			setErrorMessage();
		}
	}, [httpError]);

	const handleAuthSubmit = async (data) => {
		const path = isLoginForm ? '/login' : '';
		clearHttpError();

		try {
			const userResponse = await sendRequest(
				'post',
				`${process.env.REACT_APP_BACKEND_URL}/users${path}`,
				data
			);
			auth.login(
				userResponse.user.id,
				userResponse.user.name,
				userResponse.user.token
			);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<DefaultLayout>
			<Page
				pageTitle={isLoginForm ? 'Login' : 'Registrazione'}
				spaced
				bodyWidth={400}>
				<EuiPanel>
					<EuiForm component='form' onSubmit={handleSubmit(handleAuthSubmit)}>
						{!isLoginForm && (
							<React.Fragment>
								<EuiFormRow
									label='Nome'
									isInvalid={!!errors.name}
									error={errors.name && [errors.name.message]}>
									<EuiFieldText
										placeholder='Nome'
										name='name'
										fullWidth
										isInvalid={!!errors.name}
										inputRef={register({
											required: 'Campo obbligatorio',
										})}
									/>
								</EuiFormRow>
							</React.Fragment>
						)}
						<EuiFormRow
							fullWidth
							label='Email'
							isInvalid={!!errors.email}
							error={errors.email && [errors.email.message]}>
							<EuiFieldText
								inputRef={register({
									required: 'Campo obbligatorio',
									pattern: {
										value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
										message: 'Inserire un indirizzo email valido',
									},
								})}
								name='email'
								fullWidth
								placeholder='Email'
								isInvalid={!!errors.email}
							/>
						</EuiFormRow>
						<EuiFormRow
							fullWidth
							label='Password'
							isInvalid={!!errors.password}
							error={errors.password && [errors.password.message]}>
							<EuiFieldPassword
								inputRef={register({
									required: 'Campo obbligatorio',
									minLength: {
										value: 6,
										message:
											'Inserire una password di almeno 6 caratteri',
									},
								})}
								name='password'
								fullWidth
								placeholder='Password'
								type={'dual'}
								isInvalid={!!errors.password}
							/>
						</EuiFormRow>
						<EuiSpacer />
						{errorMessage && (
							<EuiTextColor color='danger'>{errorMessage}</EuiTextColor>
						)}

						<EuiFormRow fullWidth hasEmptyLabelSpace>
							<EuiButton isLoading={isLoading} type='submit' fullWidth>
								{isLoginForm ? 'Login' : 'Registrazione'}
							</EuiButton>
						</EuiFormRow>
						<EuiSpacer size='xl' />
						{process.env.NODE_ENV === 'development' && (
							<EuiFlexGroup justifyContent='flexEnd'>
								<EuiFlexItem grow={false}>
									<EuiButtonEmpty onClick={handleAuthFormChange}>
										{isLoginForm ? 'Registrazione' : 'Login'}
									</EuiButtonEmpty>
								</EuiFlexItem>
							</EuiFlexGroup>
						)}
					</EuiForm>
				</EuiPanel>
			</Page>
		</DefaultLayout>
	);
};

export { Auth };
