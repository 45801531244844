import { useState, useCallback, useEffect } from 'react';
let logoutTimer;

export const useAuth = () => {
	const [token, setToken] = useState(false);
	const [userData, setUserData] = useState(false);
	const [tokenExpirationDate, setTokenExpirationDate] = useState();

	const login = useCallback((id, name, token, expirationDate) => {
		setToken(token);
		setUserData({ id, name });
		// tokenExpirationDate = 1h
		const tokenExpirationDate =
			expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 12);

		setTokenExpirationDate(tokenExpirationDate);
		localStorage.setItem(
			'userData',
			JSON.stringify({
				id: id,
				name: name,
				token: token,
				expiration: tokenExpirationDate.toISOString(),
			})
		);
	}, []);

	const logout = useCallback(() => {
		setToken(null);
		setUserData({ id: null, name: null });
		setTokenExpirationDate(null);
		localStorage.removeItem('userData');
	}, []);

	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [token, logout, tokenExpirationDate]);

	useEffect(() => {
		const storedUserData = JSON.parse(localStorage.getItem('userData'));
		if (
			storedUserData &&
			storedUserData.token &&
			new Date(storedUserData.expiration) > new Date()
		) {
			login(
				storedUserData.id,
				storedUserData.name,
				storedUserData.token,
				new Date(storedUserData.expiration)
			);
		}
	}, [login]);

	return { token, userData, login, logout };
};
