import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import DefaultLayout from '../layout/default';

import Auth from '../pages/Auth';
const Home = React.lazy(() => import('../pages/Home'));
const Profile = React.lazy(() => import('../pages/Profile'));
const DataSource = React.lazy(() => import('../pages/DataSource'));
const DataSources = React.lazy(() => import('../pages/DataSources'));
const Catalog = React.lazy(() => import('../pages/Catalog'));
const Databases = React.lazy(() => import('../pages/Databases'));
const Database = React.lazy(() => import('../pages/Database'));

export const RoutedContent = (props) => {
	const { isLoggedIn } = props;
	let routes;

	if (isLoggedIn) {
		routes = (
			<Switch>
				<Route exact path='/'>
					<Home />
				</Route>
				<Route path='/user' exact>
					<Profile />
				</Route>
				<Route exact path='/data-sources'>
					<DataSources />
				</Route>
				<Route path='/data-sources/new'>
					<DataSource isNewSource={true} />
				</Route>
				<Route path='/data-sources/:sourceId'>
					<DataSource isNewSource={false} />
				</Route>
				<Route path='/catalog'>
					<Catalog />
                </Route>
                <Route exact path='/databases'>
					<Databases />
                </Route>
                <Route path='/databases/:dbName'>
					<Database />
                </Route>
				<Redirect to='/' />
			</Switch>
		);
	} else {
		routes = (
			<Switch>
				<Route path='/auth'>
					<Auth />
				</Route>
				<Redirect to='/auth' />
			</Switch>
		);
	}

	return (
		<Router>
			<Suspense fallback={<DefaultLayout />}>{routes}</Suspense>
		</Router>
	);
};
