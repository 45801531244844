import { useContext } from 'react';
import { APIMessageContext } from '../context/message-context';

export const useMessage = () => {
	const { toastState, dispatchToast, addToast } = useContext(
		APIMessageContext
	);

	return { toastState, dispatchToast, addToast };
};
