import React from 'react';
import { EuiGlobalToastList } from '@elastic/eui';
import { useMessage } from '../../../shared/hooks/message-hook';
// import { useToast } from '../../../shared/context/message-context';

const Message = () => {
	const { toastState, dispatchToast } = useMessage();
	const removeToast = (toast) => {
		dispatchToast({ type: 'REMOVE_TOAST', toast: toast });
	};

	return (
		<div style={{ maxWidth: 320 }}>
			<EuiGlobalToastList
				toasts={toastState.toasts}
				dismissToast={removeToast}
				toastLifeTimeMs={5000}
			/>
		</div>
	);
};

export { Message };
