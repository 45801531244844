import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../shared/context/auth-context';

import {
	EuiAvatar,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHeader,
	EuiHeaderLogo,
	EuiHeaderSection,
	EuiHeaderSectionItem,
	EuiHeaderSectionItemButton,
	EuiIcon,
	EuiKeyPadMenu,
	EuiKeyPadMenuItem,
	EuiLink,
	EuiPopover,
	EuiHeaderLinks,
	EuiHeaderLink,
	EuiSpacer,
	EuiText,
} from '@elastic/eui';
import { htmlIdGenerator } from '@elastic/eui/lib/services';

const Header = () => {
	const auth = useContext(AuthContext);
	const history = useHistory();
	const renderLogo = () => (
		<EuiHeaderLogo
			iconType='logoElastic'
			href='#'
			onClick={(e) => e.preventDefault()}
			aria-label='Go to home page'>
			DARE DMP
		</EuiHeaderLogo>
	);

	const renderLinks = () => {
		return auth.isLoggedIn ? (
			<EuiHeaderLinks aria-label='Home'>
				<EuiHeaderLink onClick={() => history.push(`/`)}>Home</EuiHeaderLink>
				<EuiHeaderLink onClick={() => history.push(`/data-sources`)}>
					Fonti Dati
				</EuiHeaderLink>
				<EuiHeaderLink onClick={() => history.push(`/catalog`)}>
					Catalogo
                </EuiHeaderLink>
                <EuiHeaderLink onClick={() => history.push(`/databases`)}>
					MongoDB
                </EuiHeaderLink>
			</EuiHeaderLinks>
		) : (
			<EuiHeaderLinks aria-label='App navigation dark theme example'>
				<EuiHeaderLink onClick={() => history.push(`/auth`)}>Login</EuiHeaderLink>
				<EuiHeaderLink onClick={() => history.push(`/catalog`)}>
					Catalogo
				</EuiHeaderLink>
			</EuiHeaderLinks>
		);
	};

	return (
		<EuiHeader theme='dark'>
			<EuiHeaderSection grow={false}>
				<EuiHeaderSectionItem border='right'>{renderLogo()}</EuiHeaderSectionItem>
				<EuiHeaderSectionItem border='right'>
					{renderLinks()}
				</EuiHeaderSectionItem>
			</EuiHeaderSection>

			{auth.isLoggedIn && (
				<EuiHeaderSection side='right'>
					<EuiHeaderSectionItem>
						<HeaderUserMenu />
					</EuiHeaderSectionItem>
				</EuiHeaderSection>
			)}
		</EuiHeader>
	);
};

const HeaderUserMenu = () => {
	const auth = useContext(AuthContext);
	const history = useHistory();
	const id = htmlIdGenerator()();
	const [isOpen, setIsOpen] = useState(false);

	// console.log(auth);

	const onMenuButtonClick = () => {
		setIsOpen(!isOpen);
	};

	const closeMenu = () => {
		setIsOpen(false);
	};

	const button = (
		<EuiHeaderSectionItemButton
			aria-controls={id}
			aria-expanded={isOpen}
			aria-haspopup='true'
			aria-label='Account menu'
			onClick={onMenuButtonClick}>
			<EuiAvatar name={auth.name || '??'} size='s' />
		</EuiHeaderSectionItemButton>
	);

	return (
		<EuiPopover
			id={id}
			ownFocus
			button={button}
			isOpen={isOpen}
			anchorPosition='downRight'
			closePopover={closeMenu}
			panelPaddingSize='none'>
			<div style={{ width: 320 }}>
				<EuiFlexGroup
					gutterSize='m'
					className='euiHeaderProfile'
					responsive={false}>
					<EuiFlexItem grow={false}>
						<EuiAvatar name={auth.name || '??'} size='xl' />
					</EuiFlexItem>

					<EuiFlexItem>
						<EuiText>
							<p>{auth.name || '??'}</p>
						</EuiText>

						<EuiSpacer size='m' />

						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiFlexGroup justifyContent='spaceBetween'>
									<EuiFlexItem grow={false}>
										<EuiLink onClick={() => history.push(`/user`)}>
											Modifica Profilo
										</EuiLink>
									</EuiFlexItem>

									<EuiFlexItem grow={false}>
										<EuiLink onClick={() => auth.logout()}>
											Log out
										</EuiLink>
									</EuiFlexItem>
								</EuiFlexGroup>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
				</EuiFlexGroup>
			</div>
		</EuiPopover>
	);
};

const HeaderAppMenu = () => {
	const idGenerator = htmlIdGenerator();
	const popoverId = idGenerator('popover');
	const keypadId = idGenerator('keypad');

	const [isOpen, setIsOpen] = useState(false);

	const onMenuButtonClick = () => {
		setIsOpen(!isOpen);
	};

	const closeMenu = () => {
		setIsOpen(false);
	};

	const button = (
		<EuiHeaderSectionItemButton
			aria-controls={keypadId}
			aria-expanded={isOpen}
			aria-haspopup='true'
			aria-label='Apps menu with 1 new app'
			notification='1'
			onClick={onMenuButtonClick}>
			<EuiIcon type='apps' size='m' />
		</EuiHeaderSectionItemButton>
	);

	return (
		<EuiPopover
			id={popoverId}
			ownFocus
			button={button}
			isOpen={isOpen}
			anchorPosition='downRight'
			closePopover={closeMenu}>
			<EuiKeyPadMenu id={keypadId} style={{ width: 288 }}>
				<EuiKeyPadMenuItem label='Discover'>
					<EuiIcon type='discoverApp' size='l' />
				</EuiKeyPadMenuItem>

				<EuiKeyPadMenuItem label='Dashboard'>
					<EuiIcon type='dashboardApp' size='l' />
				</EuiKeyPadMenuItem>

				<EuiKeyPadMenuItem label='Dev Tools'>
					<EuiIcon type='devToolsApp' size='l' />
				</EuiKeyPadMenuItem>

				<EuiKeyPadMenuItem label='Machine Learning'>
					<EuiIcon type='machineLearningApp' size='l' />
				</EuiKeyPadMenuItem>

				<EuiKeyPadMenuItem label='Graph'>
					<EuiIcon type='graphApp' size='l' />
				</EuiKeyPadMenuItem>

				<EuiKeyPadMenuItem label='Visualize'>
					<EuiIcon type='visualizeApp' size='l' />
				</EuiKeyPadMenuItem>

				<EuiKeyPadMenuItem label='Timelion' betaBadgeLabel='Beta'>
					<EuiIcon type='timelionApp' size='l' />
				</EuiKeyPadMenuItem>
			</EuiKeyPadMenu>
		</EuiPopover>
	);
};

export { Header };
