import React from 'react';
import {
	EuiSpacer,
	EuiPageBody,
	EuiPageContent,
	EuiPageContentBody,
	EuiPageContentHeader,
	EuiPageContentHeaderSection,
	EuiPageHeader,
	EuiPageHeaderSection,
	EuiTitle,
} from '@elastic/eui';

const Page = (props) => {
	const {
		pageTitle,
		contentTitle,
		spaced,
		centered,
		bodyWidth,
		children,
		pageClass,
	} = props;
	return (
		<React.Fragment>
			<EuiPageBody component='div' restrictWidth={bodyWidth}>
				{pageTitle ? (
					<EuiPageHeader>
						<EuiPageHeaderSection>
							<EuiTitle size='l'>
								<h1>{pageTitle}</h1>
							</EuiTitle>
							<EuiSpacer size='m' />
						</EuiPageHeaderSection>
					</EuiPageHeader>
				) : (
					<EuiSpacer size='xl' />
				)}
				{!spaced ? (
					<EuiPageContent
						className={pageClass}
						verticalPosition={centered ? 'center' : null}
						horizontalPosition={centered ? 'center' : null}>
						{contentTitle && (
							<EuiPageContentHeader>
								<EuiPageContentHeaderSection>
									<EuiTitle>
										<h2>{contentTitle}</h2>
									</EuiTitle>
								</EuiPageContentHeaderSection>
							</EuiPageContentHeader>
						)}
						<EuiPageContentBody>{children}</EuiPageContentBody>
					</EuiPageContent>
				) : (
					<React.Fragment>
						{contentTitle && (
							<React.Fragment>
								<EuiPageContentHeader>
									<EuiPageContentHeaderSection>
										<EuiTitle>
											<h2>{contentTitle}</h2>
										</EuiTitle>
									</EuiPageContentHeaderSection>
								</EuiPageContentHeader>
								<EuiSpacer size='xl' />
							</React.Fragment>
						)}
						{children}
					</React.Fragment>
				)}
			</EuiPageBody>
		</React.Fragment>
	);
};

export { Page };
