import { useState, useCallback, useRef, useEffect } from 'react';

const axios = require('axios');
const FileSaver = require('file-saver');

export const useHttpClient = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [httpError, setHttpError] = useState();

	const activeHttpRequests = useRef([]);

	const sendRequest = useCallback(
		async (method = 'get', url, data = null, headers = {}, fileName = null) => {
			setIsLoading(true);

			const cancelToken = axios.CancelToken;
			const source = cancelToken.source();
			activeHttpRequests.current.push(source);

			try {
				const axiosParams = {
					method,
					url,
					data,
					headers,
					cancelToken: source.token,
				};

				if (fileName) {
					axiosParams.responseType = 'blob';
				}

				let response = await axios(axiosParams);

				activeHttpRequests.current = activeHttpRequests.current.filter(
					(src) => src.token !== source.token
				);
				setIsLoading(false);

				if (!fileName) {
					return response.data;
				} else {
					const blob = new Blob([response.data], {
						type: response.headers['content-type'],
					});

					FileSaver.saveAs(blob, fileName);
				}
			} catch (err) {
				console.log(`ERROR [HTTP REQUEST]`);
				console.dir(err.response);
				setHttpError(err.message);
				setIsLoading(false);
				throw err;
			}
		},
		[]
	);

	const clearHttpError = () => {
		setHttpError(null);
	};

	useEffect(() => {
		return () => {
			activeHttpRequests.current.forEach((source) =>
				source.cancel('axios request cancelled')
			);
		};
	}, []);

	return { isLoading, httpError, sendRequest, clearHttpError };
};
