import React from 'react';
import { EuiPage, EuiSpacer } from '@elastic/eui';

import Header from '../../components/common/header';
import Sidebar from '../sidebar';
import Footer from '../../components/common/footer';

const DefaultLayout = (props) => {
	const { sidebar, pageWidth, fullscreen, children } = props;

	return (
		<React.Fragment>
			<Header />
			<EuiSpacer size='xxl' />
			<EuiPage
				restrictWidth={pageWidth || 1000}
				style={fullscreen ? { height: '100%' } : null}>
				{sidebar && <Sidebar />}
				{children}
			</EuiPage>
			{/* <Message /> */}
			<Footer />
		</React.Fragment>
	);
};

export { DefaultLayout };
