import React, { useReducer, createContext } from 'react';

const initialToasts = { toasts: [] };

const toastReducer = (state, action) => {
	switch (action.type) {
		case 'ADD_TOAST':
			const toast = action.toast;
			toast.id = `toast-${[Math.floor(Math.random() * 100)]}`;
			return {
				...state,
				toasts: [...state.toasts].concat(toast),
			};
		case 'REMOVE_TOAST':
			return {
				...state,
				toasts: [...state.toasts].filter(
					(toast) => toast.id !== action.toast.id
				),
			};
		case 'REMOVE_TOASTS':
			return {
				toasts: [],
			};
		default:
			return state;
	}
};

export const APIMessageContext = createContext();

export const APIMessageProvider = ({ children }) => {
	const [toastState, dispatchToast] = useReducer(
		toastReducer,
		initialToasts
	);

	const addToast = (message) => {
		let toast = { title: message.title, text: message.text };
		switch (message.type) {
			case 'success':
				toast.color = 'success';
				toast.iconType = 'check';
				break;
			case 'warning':
				toast.color = 'warning';
				toast.iconType = 'minusInCircle';
				break;
			case 'error':
				toast.color = 'danger';
				toast.iconType = 'alert';
				break;
			default:
				break;
		}
		return dispatchToast({ type: 'ADD_TOAST', toast: toast });
	};

	return (
		<APIMessageContext.Provider
			value={{
				toastState,
				dispatchToast,
				addToast,
			}}>
			{children}
		</APIMessageContext.Provider>
	);
};
