import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RoutedContent } from './routes';

import { AuthContext } from './shared/context/auth-context';
import { APIMessageProvider } from './shared/context/message-context';
// import { APIMessageProvider } from './shared/context/message-context';
import Message from './components/common/message';
import { useAuth } from './shared/hooks/auth-hook';

import './App.css';

const basePath = process.env.BASE_PATH || '/';

const App = () => {
	const { token, userData, login, logout } = useAuth();
	console.log('app data');
	console.log(userData);

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token,
				token: token,
				userId: userData.id,
				name: userData.name,
				login: login,
				logout: logout,
			}}>
			<APIMessageProvider>
				<Router basename={basePath}>
					<RoutedContent isLoggedIn={!!token} role={userData.role} />
				</Router>
				<Message />
			</APIMessageProvider>
		</AuthContext.Provider>
	);
};

export default App;
